var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricingPlan"},[_c('NavBarVue'),_c('div',[_vm._m(0),_c('div',{staticClass:"price-container"},[_c('div',{staticClass:"cols"},[_c('b',{staticClass:"header-text"},[_vm._v("Solo Plan")]),_c('p',[_vm._v("Perfect for starting with us!")]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"button"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.button_icon),expression:"button_icon"}],attrs:{"href":_vm.base + '28oaFD2L9fdgcH628h'}},[_vm._v("Select Plan")])])]),_c('div',{staticClass:"cols"},[_c('b',{staticClass:"header-text"},[_vm._v("Freelancer Plan")]),_c('p',[_vm._v("Always like the Standard!")]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"button"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.button_icon),expression:"button_icon"}],attrs:{"href":_vm.base + 'dR69Bz71p7KO7mM28g'}},[_vm._v("Select Plan")])])]),_c('div',{staticClass:"cols"},[_c('b',{staticClass:"header-text"},[_vm._v("Studio Plan")]),_c('p',[_vm._v("Make your self comfortable!")]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"button"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.button_icon),expression:"button_icon"}],attrs:{"href":_vm.base + '28o8xvgBZ2qubD26ov'}},[_vm._v("Select Plan")])])]),_c('div',{staticClass:"cols"},[_c('b',{staticClass:"header-text"},[_vm._v("Agency Plan")]),_c('p',[_vm._v("Make plans as per your requirements")]),_vm._m(7),_vm._m(8),_c('div',{staticClass:"button"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.button_icon),expression:"button_icon"}],attrs:{"href":_vm.base + 'dR69Bz2L97KO22s4gm'}},[_vm._v("Select Plan")])])])])]),_c('div',{staticClass:"skip"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v(_vm._s(_vm.error))])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('b',{staticStyle:{"color":"black"}},[_vm._v(" A pricing plan that grows with your business ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('b',[_vm._v("$6.99/m")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"numprocess"},[_c('span',[_c('strong',[_vm._v("WooCommerce Store")])]),_c('br'),_c('b',[_vm._v("01")]),_c('br'),_c('p'),_c('p')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('b',[_vm._v("$16.99/m")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"numprocess"},[_c('span',[_c('strong',[_vm._v("WooCommerce Store")])]),_c('br'),_c('b',[_vm._v("05")]),_c('br'),_c('p'),_c('p')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('b',[_vm._v("$46.99/m")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"numprocess"},[_c('span',[_c('strong',[_vm._v("WooCommerce Store")])]),_c('br'),_c('b',[_vm._v("25")]),_c('br'),_c('p'),_c('p')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('b',[_vm._v("$96.99/m")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"numprocess"},[_c('span',[_c('strong',[_vm._v("WooCommerce Store")])]),_c('br'),_c('b',[_vm._v("100")]),_c('br'),_c('p'),_c('p')])
}]

export { render, staticRenderFns }
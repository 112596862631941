<template>
  <div class="tos">
    <NavBarVue />
    <div class="tos-container">
      <div class="heading">
        <b></b>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>Terms and conditions</b>
        </div>
        <div class="content">
          <p>
            We build software as a web service that people (our “Clients”) can
            use to resize, optimize and serve their . Clients, in turn, make
            their images available to their customers (“End Users”). Satak
            Support (“WooChitra”, “we” or “us”) offers various services to you,
            our Client and your End Users, through our website
            (www.woochitra.com), all of which are conditioned on your agreement
            to adhere to the following Terms of Service without modification of
            any kind. Your use of the Service and/or your registration with us
            constitutes your agreement to these Terms of Service. These Terms of
            Service are subject to change at any time, without prior notice. You
            are responsible for reviewing these Terms of Service on a regular
            basis. These Terms of Service apply to all visitors and all who
            access our website or Service.
          </p>
        </div>
      </div>
      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>PRIVACY</b>
        </div>
        <div class="content">
          <p>
            We collect data from the users of our service. woochitra.com does not
            interact with End Users on your website. You affirm that you are
            either more than 18 years of age, or an emancipated minor, or
            possess legal parental or guardian consent, and are fully able and
            competent to enter into the terms, conditions, obligations,
            affirmations, representations, and warranties set forth in these
            Terms of Service, and to abide by and comply with these Terms of
            Service. In any case, you represent that you are over the age of 13,
            as this Service is not intended for children under 13. If you are
            under 13 years of age, then please do not use our Service.
          </p>
        </div>
      </div>

      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>1. DEFINITIONS</b>
        </div>
        <div class="content">
          <p>
            “CDN” means Content Delivery Network and refers to a supplier of
            woochitra.com “Origin Image” means the image You want to transform
            with the Services prior to Transformation as provided by you.
            “Cached Image” means a copy of the Origin Image as well as the
            Transformed Images temporarily stored on woochitra.com servers.
            “Transformation” means the image processing operation from
            woochitra.com, including but not limited to resizing, cropping,
            watermarking and quality alteration applied to the Origin Image.
            “Transformed Image” means the image after application of the
            Transformation supported by woochitra.com. “Image Server” means a
            server maintained by us responsible for the Transformation. “User
            Content” means the (image) material You provide to woochitra.com for
            Caching, applying Transformations and delivering over a CDN.
            “Consumer” means the end user to whom Transformed Image(s) are
            delivered to.
          </p>
        </div>
      </div>

      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>2. REGISTRATION</b>
        </div>
        <div class="content">
          <p>
            By registering and participating in this Service as a Client, you
            agree and represent as follows: 1. You are of legal age and are
            otherwise capable of forming a legally binding contract; 2. All
            information you submit to woochitra.com or in connection with our
            Service is accurate and complete and that you will maintain and
            promptly update any profile supplied to us to ensure accuracy at all
            times; 3. You agree to be contacted via Email, SMS or live chat by
            woochitra.com, and third parties if relevant, regarding WooChitra
            services; 4. You hereby grant woochitra.com permission to email or
            display your profile and such other information as may be supplied
            by you to us on or from our website as we shall deem advisable in
            our sole determination in connection with the Service or for
            marketing purposes thereof; 5. By using the Service, you are
            granting us permission to access your account and those messages,
            data, information, text, graphics, audio, video or other material
            posted/uploaded/transmitted to or through the Service using your
            account, solely in connection with the provision of Services. You
            may visit our Site without registering. However, in order to buy our
            service, you will be required to register for an account. When
            creating an account we will collect your email. If you decide to
            purchase any of our products, you will also be required to submit
            additional information such as your website, your PayPal or Credit
            Card information to our third party payment processors. We may also
            ask you for additional information if necessary.
          </p>
        </div>
      </div>

      <!-- second -->
      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>3. RESPONSIBILITY FOR USER CONTENT</b>
        </div>
        <div class="content">
          <p>
            woochitra.com respects the rights of third party creators and content
            owners and expects that you will do the same. Given the nature of
            the Service and the volume of information submitted, we cannot and
            do not monitor all of the Materials posted or transmitted by you and
            other third-party information providers via the Service, including,
            without limitation, any Materials posted via the website. You
            expressly agree that we: (a) will not be liable for Materials and
            (b) reserve the right to review, reject, delete, remove, modify, or
            edit any Materials at any time for any reason, without liability and
            without notice to you. We reserve the right but are not obligated to
            remove User Content from the Service for any reason, including User
            Content that we believe violates these Terms or our Acceptable Use
            Policy below.
          </p>
        </div>
      </div>
      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>4. ACCEPTABLE USE POLICY</b>
        </div>
        <div class="content">
          <p>
            The following is a partial list of the kinds of activities that are
            prohibited on or through the Service: (a) submitting Materials that
            are patently offensive to the online community, such as content that
            promotes racism, bigotry, hatred or physical harm of any kind
            against any group or individual; (b) engaging in activities or
            submitting Materials that could be harmful to minors; (c) engaging
            in activity or submitting Materials that harasses or advocates
            harassment of another person; (d) engaging in activity that involves
            the transmission of “junk mail” or unsolicited mass mailing or
            “spam” or harvesting or otherwise collecting personally identifiable
            information about Service users, including names, phone numbers,
            addresses, email addresses, (collectively, “User Data”) without
            their consent; (e) engaging in activity, or submitting Materials, or
            promoting information that is false, misleading or promotes illegal
            activities or conduct that is abusive, threatening, obscene,
            defamatory or libelous; (f) submitting Materials that contain
            restricted or password only access pages, or hidden pages or images;
            (g) submitting Materials that displays pornographic or sexually
            explicit material of any kind; (h) submitting Materials that provide
            instructional information about illegal activities such as making or
            buying illegal weapons, violating someone’s privacy, or providing or
            creating computer viruses; (i) submitting Materials that contain
            viruses, Trojan horses, worms, or any other similar forms of
            malware, (j) engaging in activities or submitting Materials that
            solicit passwords or personally identifiable information for
            unlawful purposes from other users; (k) engaging in unauthorized
            commercial activities and/or sales without our prior written consent
            such as advertising, solicitations, contests, sweepstakes, barter,
            and pyramid schemes; (l) using any device, software, or routine to
            interfere or attempt to interfere with the proper working of the
            Service; (o) taking any action that imposes an unreasonable or
            disproportionately large load on the Service or our hardware and
            software infrastructure or that of any of our Licensors or
            Suppliers. In addition, you covenant and represent that you will not
            use the Service in violation of the law or these Terms of Service.
          </p>
        </div>
      </div>

      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>5. USE OF THIRD PARTY SERVICES</b>
        </div>
        <div class="content">
          <p>
            As a part of our Service, we may offer links to websites operated by
            various third parties and are not responsible or liable for any acts
            or omissions created or performed by these third parties. We provide
            such links for your convenience and reference only. woochitra.com
            does not operate or control in any way any information, software,
            products or services available on such websites. Our inclusion of a
            link to a website does not imply any endorsement of the services or
            the site, its contents, or its sponsoring organization.
          </p>
        </div>
      </div>
      <!-- second -->
      <div class="sub-container">
        <div class="header-text">
          <b>6. REPRESENTATIONS AND WARRANTIES AND INDEMNIFICATION</b>
        </div>
        <div class="content">
          <p>
            You hereby represent and warrant that: (a) you have all necessary
            authority, rights and permissions to submit the Materials and grant
            the licenses described in these Terms of Service, (b) the Materials
            are accurate, current and complete, (c) the Materials and your use
            of the Service shall not infringe or misappropriate any copyright,
            trademark, trade secret, patent, or other intellectual property
            right of any third party or violate any other third party rights,
            including, without limitation, any rights of privacy or publicity or
            any contractual rights, (d) the Materials and your use of the
            Service shall not violate any applicable law or regulation or cause
            injury to any person; (e) your use of the Service shall not violate
            any agreements between you and a third party. You agree to
            indemnify, defend and hold harmless woochitra.com, and its officers,
            directors, employees, agents, and contractors from and against any
            and all claims, costs, demands, damages, liabilities, or expenses,
            including, without limitation, reasonable attorneys’ fees, arising
            from or related to: (a) the Materials you provide or approve for
            publication, (b) your use of the Service, (c) your breach of these
            Terms of Service, or (d) any actual, prospective, completed or
            terminated service between you and a third party.
          </p>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>7. DISCLAIMERS AND LIMITATIONS</b>
        </div>
        <div class="content">
          <p>
            woochitra.com intends that the information contained in its Service
            be accurate and reliable; however, errors sometimes occur. In
            addition, woochitra.com may make changes and improvements to the
            information provided herein at any time. THE SERVICE AND THE
            INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ASSOCIATED WITH IT ARE
            PROVIDED “AS IS.” woochitra.com AND/OR ITS SUPPLIERS, PARTNERS AND
            AFFILIATES DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO THE SERVICE AND ANY
            INFORMATION, SOFTWARE, PRODUCTS AND SERVICES PROVIDED HEREIN,
            INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON
            INFRINGEMENT. USE OF woochitra.com’ SERVICE IS AT YOUR OWN RISK.
            woochitra.com AND/OR ITS SUPPLIERS, ARE NOT LIABLE FOR ANY DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR
            OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF
            woochitra.com WEBSITE OR SERVICE OR WITH THE DELAY OR INABILITY TO
            USE THE SERVICE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND
            SERVICES OBTAINED THROUGH woochitra.com, OR OTHERWISE ARISING OUT OF
            THE USE OF THE SERVICE, WHETHER RESULTING IN WHOLE OR IN PART, FROM
            BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN
            IF woochitra.com AND/OR ITS SUPPLIERS HAD BEEN ADVISED OF THE
            POSSIBILITY OF DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
            APPLY TO YOU. LIMITATION OF LIABILITY IN NO EVENT SHALL woochitra.com
            OR ITS SUPPLIERS BE LIABLE FOR LOST PROFITS OR ANY SPECIAL,
            INCIDENTAL OR CONSEQUENTIAL DAMAGES (HOWEVER ARISING, INCLUDING
            NEGLIGENCE) ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT.
            woochitra.com LIABILITY, AND THE LIABILITY OF woochitra.com SUPPLIERS
            AND AFFILIATES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE,
            SHALL NOT EXCEED THE TOTAL SUM OF $100.00. THE LIMITATIONS OF
            LIABILITY SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE CONTRACT
            BETWEEN woochitra.com AND YOU. Some states do not allow the
            limitation of liability, so the foregoing limitation may not always
            apply. For Jurisdictions that do not allow us to limit our
            liability: Notwithstanding any provision of these Terms, if your
            jurisdiction has provisions specific to waiver or liability that
            conflict with the above then our liability is limited to the
            smallest extent possible by law. Specifically, in those
            jurisdictions not allowed, we do not disclaim liability for: (a)
            death or personal injury caused by its negligence or that of any of
            its officers, employees or agents; or (b) fraudulent
            misrepresentation; or (c) any liability which it is not lawful to
            exclude either now or in the future.
          </p>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>8. SUBSCRIPTIONS AND PAYMENT</b>
        </div>
        <div class="content">
          <p>
            After browsing our website you may be interested in purchasing some
            of our woochitra.com subscription plans. You will be required to
            submit payment information to do so. To process our payments we use
            Stripe. You must agree with their terms and conditions before paying
            for any of our products. woochitra.com offers various subscription
            packages. This subscription will automatically renew at the end of
            the month, and you will be automatically billed at the end of your
            term for the month if your subscription is still active. You can,
            however, cancel your subscription at any time. If you do not cancel
            by the end of the month, you will be automatically billed for the
            next month. Upon receipt of your order, you will either be prompted
            to begin your account activation immediately or you will receive an
            e-mail from us with instructions to complete your account
            activation. If you are prompted to begin your account activation
            immediately, your completion of the account activation will
            constitute delivery to you of the item(s) you purchased. If you
            receive an e-mail from us with instructions to complete your account
            activation, the receipt by you of the e-mail will constitute
            delivery by us to you of the item(s) you purchased. In case you are
            unable to activate your account you purchased or you do not receive
            an e-mail from us with instructions to complete your account
            activation, you must contact us within 5 days from the date of your
            order. If you do not contact us within 5 days from the date of your
            order, the item(s) you purchased will be considered received,
            downloaded and delivered to you.
          </p>
          <div class="refund">
            <b>Refund Policy</b>
            : We offer refunds to any unsatisfied customer up to seven (7) days
            after the purchase was made. If you would like your money back,
            simply send us an email at help@woochitra.com with a description of
            your problem and request for a refund.
          </div>
          <div class="refund">
            <b>Refund Policy</b>
            : We offer refunds to any unsatisfied customer up to seven (7) days
            after the purchase was made. If you would like your money back,
            simply send us an email at help@woochitra.com with a description of
            your problem and request for a refund.
          </div>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>10. TERMINATION</b>
        </div>
        <div class="content">
          <p>
            woochitra.com reserves the right in its sole discretion, and without
            any prior notice, to terminate your access to the Service for any or
            no reason, including your breach of these Terms of Service, the
            terms and conditions of any service for which you may have
            registered, or a violation of the rights of another user or the law.
            You may unsubscribe from any further communication from woochitra.com
            at any time by delivering a written notice addressed to us.
            woochitra.com reserves the right with or without notice to you at any
            time to change, modify or discontinue any service or a portion or
            attribute thereof, or the offering of any information, good,
            content, product or service. woochitra.com shall have no liability to
            you or any third party should woochitra.com modify or discontinue any
            service or an aspect thereof.
          </p>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>11. DISPUTE RESOLUTION</b>
        </div>
        <div class="content">
          <p>
            Any claim or controversy arising out of or relating to the use of
            WooChitra’ Service, to the goods or services provided by WooChitra,
            or to any acts or omissions for which you may contend WooChitra is
            liable, including but not limited to any claim or controversy
            (“Dispute”), shall be finally, and exclusively, settled by
            arbitration in Meerut, India, from which arbitration there shall be
            no appeal. The arbitration shall be held before one arbitrator under
            the Commercial Arbitration rules of the American Arbitration
            Association (“AAA”) in force at that time. The arbitrator shall be
            selected pursuant to the AAA rules. The arbitrator shall apply the
            substantive law of India, except that the interpretation and
            enforcement of this arbitration provision shall be governed by the
            Federal Arbitration Act. To begin the arbitration process, a party
            must make a written demand therefore. Each part shall bear its own
            costs and attorneys’ fees. Any judgment upon the award rendered by
            the arbitrators may be entered in any court of competent
            jurisdiction in India. The arbitrator shall not have the power to
            award damages in connection with any Dispute in excess of actual
            compensatory damages and shall not multiply actual damages or award
            consequential, punitive or exemplary damages, and each party
            irrevocably waives any claim thereto. The agreement to arbitrate
            shall not be construed as an agreement to the joinder or
            consolidation of arbitration under this Agreement with arbitration
            of disputes or claims of any non-party, regardless of the nature of
            the issues or disputes involved. THIS AGREEMENT PROVIDES THAT ALL
            DISPUTES BETWEEN YOU AND woochitra.com WILL BE RESOLVED BY BINDING
            ARBITRATION. YOU THUS GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR
            DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN OR
            BRING CLASS ACTIONS. YOU ACKNOWLEDGE AND AGREE THAT YOU AND
            woochitra.com ARE EACH WAIVING THE RIGHT TO PARTICIPATE AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR
            REPRESENTATIVE PROCEEDING. Further, unless both you and woochitra.com
            otherwise agree, the arbitrator may not consolidate more than one
            person’s claims, and may not otherwise preside over any form of any
            class or representative proceeding. YOUR RIGHTS WILL BE DETERMINED
            BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY.
          </p>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>12. CLASS ACTION/JURY TRIAL WAIVER</b>
        </div>
        <div class="content">
          <p>
            WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY
            HAVE OBTAINED OR USED THE SERVICE FOR PERSONAL, COMMERCIAL OR OTHER
            PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL
            CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
            CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
            OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS
            ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT
            CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY
            ENTERING INTO THIS AGREEMENT, YOU AND WE ARE EACH WAIVING THE RIGHT
            TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE
            ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE
            PROCEEDING OF ANY KIND.
          </p>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>13. GENERAL </b>
        </div>
        <div class="content">
          <p>
            You agree that: (i) the Service shall be deemed solely based in
            India; and (ii) this Service shall be deemed a passive website and
            service that does not give rise to personal jurisdiction over
            woochitra.com, either specific or general, in jurisdictions other
            than India. These Terms of Service shall be governed by the internal
            substantive laws of India, without respect to its conflict of laws
            principles. The application of the United Nations Convention on
            Contracts for the International Sale of Goods is expressly excluded.
            We each agree to submit to the personal jurisdiction of a state
            court located in India, for any actions for which either party
            retains the right to seek injunctive or other equitable relief, as
            further described in the Arbitration provision above. These Terms of
            Service, together with the Privacy Policy and any other legal
            notices published by us on the Service, shall constitute the entire
            agreement between you and us concerning this Service and supersedes
            any prior written or oral representations. If any provision of these
            Terms of Service is deemed invalid by a court of competent
            jurisdiction, the invalidity of such provision shall not affect the
            validity of the remaining provisions of these Terms of Service,
            which shall remain in full force and effect. No waiver of any term
            of this these Terms of Service shall be deemed a further or
            continuing waiver of such term or any other term, and our failure to
            assert any right or provision under these Terms of Service shall not
            constitute a waiver of such right or provision. YOU AND woochitra.com
            AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THIS
            SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
            ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
            These Terms of Service may not be transferred or assigned by you,
            but may be assigned by woochitra.com without restriction. Any
            attempted transfer or assignment in violation hereof shall be null
            and void. A printed version of these Terms of Service and of any
            notice given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this agreement
            to the same extent and subject to the same conditions as other
            business documents and records originally generated and maintained
            in printed form. All rights not expressly granted herein are
            reserved to woochitra.com
          </p>
        </div>
      </div>
      <div class="sub-container">
        <div class="header-text">
          <b>14. COPYRIGHT AND TRADEMARK NOTICES</b>
        </div>
        <div class="content">
          <p>
            All contents of the Service are copyrighted © Satak Support. All
            rights reserved. You may not claim intellectual or exclusive
            ownership to any of our products, modified or unmodified. All
            products are the property of Satak Support. Other product and
            company names may be trademarks or service marks of their respective
            owners. IF YOU DO NOT AGREE TO THE TERMS STATED ABOVE OR TO ANY
            CHANGES MADE IN THESE TERMS, PLEASE EXIT THIS WEBSITE AND SERVICE
            IMMEDIATELY.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarVue from "../mine/NavBar.vue";

export default {
  components: {
    NavBarVue,
  },
};
</script>

<style scoped>
.refund{
font-size: 18px;
font-family: Arial, Helvetica, sans-serif;
margin-top: 20px;
}
.refund b{
  font-size: 20px;
  
}
.dates {
  font-size: 20px;
  margin-top: 20px;
}
.sub-container {
  margin-top: 50px;
}
.content p {
  font-size: 18px;
  color: black;
  line-height: 1.6;
  margin-top: 25px;
  font-family: Arial, Helvetica, sans-serif;
}
.header-text b {
  font-size: 30px;
  margin-bottom: 30px;
}
.heading {
  font-size: 45px !important;
  text-align: center;
  margin-bottom: 50px;
}
.tos-container {
  margin-top: 150px;
  padding: 40px;
  background: white;
  margin-bottom: 50px;
}
</style>
<template>
  <div class="cancel">
      <div class="cont">
          <b>Subscription Cancelled</b><br>
          <a href="/">Back To Home</a>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.cont b{
  font-size:20px;
  color: red;
  padding: 20px !important;
}
.cont{
  margin: 50px;
}
.cancel{
  height:50vh;
  text-align: center;
}
</style>